//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [
      { icon: "mdi-plus-circle-outline", title: "Llista nova", action: "newitem" },
      { icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" },
    ],
    itemList: [],
  }),
  mounted() {
    this.getItemList();
  },
  methods: {
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu(action) {
      switch (action) {
        case "newitem":
          this.goEdit(0, 0);
          break;
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
      }
    },
    getItemList() {
      axios
        .post(api.URL() + "/api/v1/gllistagarticles00_getitemsbyuser", {
          token: sessionStorage.getItem("Token"),
          userId: parseInt(sessionStorage.getItem("UserId")),
          condition: "",
          order: "",
        })
        .then((response) => {
          if (response.error == null) {
            this.itemList = response.data;
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    goEdit(docYear, docId) {
      sessionStorage.setItem("sessDocYear", docYear);
      sessionStorage.setItem("sessDocId", docId);
      this.$router.push("/listitems00edit");
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
